<template>
  <NEmpty />
</template>

<script setup>
  import { onBeforeMount } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { NEmpty } from 'naive-ui';

  onBeforeMount(() => {
    const { params: { path }, query } = route;
    router.replace({
      path: '/' + (Array.isArray(path) ? path.join('/') : path),
      query,
    });
  });
</script>